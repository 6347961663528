<template>
	<v-dialog v-model="showAddAttachments" persistent scrollable max-width="60vw" max-height="60vh" @close="$emit('close')">
		<v-card fill-height>
			<v-card-title class="secondary">
				<v-layout fluid align-center white--text>
					<v-flex headline v-text="$t('tasks.attachments.add.title')"></v-flex>
					<v-spacer></v-spacer>
					<v-btn icon @click="$emit('close')">
						<v-icon class="white--text">close</v-icon>
					</v-btn>
				</v-layout>
			</v-card-title>
			<v-card-text>
				<v-layout v-if="loading" justify-center align-center>
					<v-progress-circular indeterminate />
				</v-layout>
				<v-layout v-else justify-center align-center>
					<QuickFoldersList
						:callback="linkAttachment"
						:document-rights="true"
						:quickfolders="quickfolders"
						:selected-vendor-id="task.vendor.id"
						:origin="'tasks'"
						@close="$emit('close')"
					/>
				</v-layout>
			</v-card-text>
			<v-card-actions>
				<v-layout row justify-end align-center> </v-layout>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import TasksManagerModuleGuard from '@/mixins/ModulesGuards/TasksManager/TasksManagerModuleGuard'

export default {
	name: 'TasksManagerActionAddAttachmentsDialog',
	components: {
		QuickFoldersList: () => ({
			component: import('@/components/QuickFolders/QuickFoldersList')
		})
	},
	mixins: [TasksManagerModuleGuard],
	props: {
		task: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			loading: true,
			showAddAttachments: true,
			quickfolders: []
		}
	},
	created: function () {
		this.service
			.getQuickFolders(this.task.vendor.id)
			.then(quickfolders => {
				if (quickfolders) {
					this.quickfolders = [...quickfolders]
				}
			})
			.finally(() => {
				this.loading = false
			})
	},
	methods: {
		linkAttachment: function (attachments) {
			const attachmentsPromises = []
			attachments.forEach(attachment => {
				attachmentsPromises.push(this.service.attachFile(this.task, attachment))
			})
			return Promise.all(attachmentsPromises).finally(() => this.$emit('close'))
		}
	}
}
</script>
